import { DocItem } from '@/types'

export const getAllyBasataResourceList = (): DocItem => ({
  title: 'Basata Resources',
  items: [
    {
      prefix: '/basata',
      items: [
        {
          title: 'Bill',
          prefix: '/bills',
          items: [
            {
              title: 'Bill Iquiry',
              path: '/inquiry',
              method: 'POST',
              content: 'getAllyBasataBillInquiry'
            },
            {
              title: 'Pay Bill',
              path: '/pay',
              method: 'POST',
              content: 'getAllyBasataBillPay'
            },
            {
              title: 'Check Status',
              path: '/status',
              method: 'POST',
              content: 'getAllyBasataBillCheckStatus'
            }
          ]
        }
      ]
    }
  ]
})
