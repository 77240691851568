<template>
  <li
    :class="[
      'hover:bg-gray-100/60 border-s-[2px] border-transparent transition-all',
      {
        '!text-secondary !border-secondary':
          $router.currentRoute.value.path === path
      }
    ]"
  >
    <router-link class="block p-1 text-base w-full" :to="path">
      <span
        :class="[
          'flex items-center',
          {
            'ms-[20px]': !method
          }
        ]"
      >
        <AppApiMethod
          v-if="method"
          :class="[
            'flex items-center justify-center min-w-[2.25rem]',
            'max-w-[2.05rem] text-[0.6rem] ms-[8px] me-2'
          ]"
          :method="method"
        />

        <span class="text-sm truncate">{{ title }}</span>
      </span>
    </router-link>
  </li>
</template>

<script setup lang="ts">
import AppApiMethod from '../ApiMethod.vue'
import { IDocSingleItem } from '@/types'

const { $router } = useNuxtApp()

defineProps<IDocSingleItem>()
</script>
