import { hasValue } from '@payiano/ha-utils'
import { Nullable } from '@payiano/ha-types'
import {
  DocItem,
  DocGroupItem,
  IDocSingleItem,
  NormalizedDocItem,
  DocEmptyGroupItem,
  PreNormalizedDocItem
} from '@/types'

export const getNormalizedPath = (...paths: Nullable<string>[]) =>
  (paths.filter(hasValue) as string[])
    .map((path) => `/${path.replace(/^\/+|\/+$/g, '')}`)
    .join('')

export const getNormalizedDocItems = (
  items: DocItem[],
  _prefix?: string,
  _isPublic?: boolean
): NormalizedDocItem[] =>
  items.reduce((carry: NormalizedDocItem[], item) => {
    const isPublic =
      item.public === undefined
        ? [undefined, true].includes(_isPublic)
        : item.public

    const options = {
      public: isPublic
    }

    const group = item as DocGroupItem | DocEmptyGroupItem
    const signle = item as IDocSingleItem
    const isGroup = !!group.items?.length
    const isEmptyGroup = !hasValue((item as DocGroupItem).title)

    if (isGroup) {
      const prefix = getNormalizedPath(_prefix, group.prefix)

      const _items = getNormalizedDocItems(group.items, prefix, isPublic)

      if (!isEmptyGroup) {
        Object.assign(options, {
          prefix,
          items: _items
        })
      } else {
        // Push items directly to carrier when item group is empty.
        carry.push(..._items)
      }
    } else {
      const _path = signle.path.startsWith('/')
        ? signle.path
        : `/${signle.path}`

      Object.assign(options, {
        path: _prefix ? `${_prefix}${_path}` : _path
      })
    }

    if (!isEmptyGroup) {
      carry.push({
        isGroup,
        ...(item as PreNormalizedDocItem),
        ...options
      })
    }

    return carry
  }, [])

export const getFlattenDrawerItems = (
  items: NormalizedDocItem[]
): NormalizedDocItem[] =>
  items.reduce(
    (items: NormalizedDocItem[], item) => [
      ...items,
      item,
      ...(item.isGroup
        ? getFlattenDrawerItems(
            (item as DocGroupItem).items as NormalizedDocItem[]
          )
        : [])
    ],
    []
  )
