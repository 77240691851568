import { DocItem } from '@/types'

export const getCompanyResourceList = (): DocItem => ({
  title: 'Company Resources',
  prefix: '/v1/company',
  items: [
    {
      title: 'App',
      prefix: '/app',
      items: [
        {
          title: 'Whoami',
          path: '/whoami',
          method: 'GET',
          content: 'getCompanyAppWhoAmI'
        }
      ]
    },
    {
      title: 'Apps',
      public: false,
      prefix: '/apps',
      items: [
        {
          title: 'List Apps',
          path: '/list',
          method: 'GET',
          content: 'getCompanyAppList'
        },
        {
          title: 'Create App',
          path: '/create',
          method: 'POST',
          content: 'getCompanyAppCreate'
        },
        {
          title: 'Reset App Token',
          path: '/reset-token',
          method: 'POST',
          content: 'getCompanyAppTokenReset'
        },
        {
          title: 'Retrieve App',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyAppRetrieve'
        },
        {
          title: 'Update App',
          path: '/update',
          method: 'PUT',
          content: 'getCompanyAppUpdate'
        }
      ]
    },
    {
      title: 'Autocomplete',
      public: false,
      prefix: '/autocomplete',
      items: [
        {
          title: 'Apps',
          path: '/apps',
          method: 'GET',
          content: 'getCompanyAutoCompleteApps'
        },
        {
          title: 'Customers',
          path: '/customers',
          method: 'GET',
          content: 'getCompanyAutoCompleteCustomers'
        },
        {
          title: 'Customer Tags',
          path: '/customer-tags',
          method: 'GET',
          content: 'getCompanyAutoCompleteCustomerTags'
        },
        {
          title: 'Payment Methods',
          path: '/payment-methods',
          method: 'GET',
          content: 'getCompanyAutoCompletePaymentMethods'
        },
        {
          title: 'Roles',
          path: '/roles',
          method: 'GET',
          content: 'getCompanyAutoCompleteRoles'
        },
        {
          title: 'Taxes',
          path: '/taxes',
          method: 'GET',
          content: 'getCompanyAutoCompleteTaxes'
        },
        {
          title: 'Webhook Event Types',
          path: '/webhook-event-types',
          method: 'GET',
          content: 'getCompanyAutoCompleteWebhookEventTypes'
        },
        {
          title: 'Webhook URLs',
          path: '/webhook-urls',
          method: 'GET',
          content: 'getCompanyAutoCompleteWebhookUrls'
        }
      ]
    },
    {
      title: 'Customers',
      prefix: '/customers',
      items: [
        {
          title: 'Customers',
          items: [
            {
              title: 'List Customers',
              path: '/list',
              method: 'GET',
              content: 'getCompanyCustomerList'
            },
            {
              title: 'Create Customer',
              path: '/create',
              method: 'POST',
              content: 'getCompanyCustomerCreate'
            },
            {
              title: 'Retrieve Customer',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyCustomerRetrieve'
            },
            {
              title: 'Update Customer',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyCustomerUpdate'
            },
            {
              title: 'Customer Addresses',
              prefix: '/customer-addresses',
              items: [
                {
                  title: 'List Addresses',
                  path: '/list',
                  method: 'GET',
                  content: 'getCompanySoloCustomerAddressList'
                },
                {
                  title: 'Create Address',
                  path: '/create',
                  method: 'POST',
                  content: 'getCompanySoloCustomerAddressCreate'
                }
              ]
            }
          ]
        },
        {
          title: 'Addresses',
          prefix: '/addresses',
          items: [
            {
              title: 'List Addresses',
              path: '/list',
              method: 'GET',
              content: 'getCompanyCustomerAddressList'
            },
            {
              title: 'Retrieve Address',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyCustomerAddressRetrieve'
            },
            {
              title: 'Update Address',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyCustomerAddressUpdate'
            },
            {
              title: 'Delete Address',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyCustomerAddressDelete'
            },
            {
              title: 'Restore Address',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyCustomerAddressRestore'
            }
          ]
        },
        {
          title: 'Tags',
          prefix: '/tags',
          items: [
            {
              title: 'List Tags',
              path: '/list',
              method: 'GET',
              content: 'getCompanyCustomerTagList'
            },
            {
              title: 'Create Tag',
              path: '/create',
              method: 'POST',
              content: 'getCompanyCustomerTagCreate'
            },
            {
              title: 'Retrieve Tag',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyCustomerTagRetrieve'
            },
            {
              title: 'Update Tag',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyCustomerTagUpdate'
            },
            {
              title: 'Delete Tag',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyCustomerTagDelete'
            },
            {
              title: 'Restore Tag',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyCustomerTagRestore'
            }
          ]
        }
      ]
    },
    {
      title: 'Company',
      public: false,
      prefix: '/company',
      items: [
        {
          title: 'Whoami',
          path: '/company',
          method: 'GET',
          content: 'getCompanyWhoAmI'
        },
        {
          title: 'Basic Info',
          prefix: '/info',
          items: [
            {
              title: 'Retrieve Info',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyBasicInfo'
            },
            {
              title: 'Update Info',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyBasicInfoUpdate'
            }
          ]
        },
        {
          title: 'Business',
          prefix: '/business',
          items: [
            {
              title: 'Retrieve Details',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyBusinessDetailsInfo'
            },
            {
              title: 'Update Details',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyBusinessDetailsUpdate'
            }
          ]
        },
        {
          title: 'Avatar',
          prefix: '/avatar',
          items: [
            {
              title: 'Upload Avatar',
              method: 'POST',
              path: '/upload',
              content: 'getCompanyAvatarUpload'
            },
            {
              title: 'Delete Avatar',
              method: 'DELETE',
              path: '/delete',
              content: 'getCompanyAvatarDelete'
            }
          ]
        },
        {
          title: 'Owners',
          prefix: '/owners',
          items: [
            {
              title: 'List Owners',
              path: '/list',
              method: 'GET',
              content: 'getCompanyOwnerList'
            },
            {
              title: 'Create Owner',
              path: '/create',
              method: 'POST',
              content: 'getCompanyOwnerCreate'
            },
            {
              title: 'Retrieve Owner',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyOwnerRetrieve'
            },
            {
              title: 'Update Owner',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyOwnerUpdate'
            },
            {
              title: 'Delete Owner',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyOwnerDelete'
            },
            {
              title: 'Restore Owner',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyOwnerRestore'
            },
            {
              title: 'Verification Documents',
              prefix: '/documents',
              items: [
                {
                  title: 'List Documents',
                  method: 'GET',
                  path: '/list',
                  content: 'getCompanyOwnerVerificationDocumentList'
                },
                {
                  title: 'Upload Document',
                  method: 'POST',
                  path: '/upload',
                  content: 'getCompanyOwnerVerificationDocumentUpload'
                }
              ]
            }
          ]
        },
        {
          title: 'Verification Documents',
          prefix: '/documents',
          items: [
            {
              title: 'List Documents',
              method: 'GET',
              path: '/list',
              content: 'getCompanyVerificationDocumentList'
            },
            {
              title: 'Upload Document',
              method: 'POST',
              path: '/upload',
              content: 'getCompanyVerificationDocumentUpload'
            }
          ]
        },
        {
          title: 'Username',
          prefix: '/username',
          items: [
            {
              title: 'Retrieve Username',
              method: 'GET',
              path: '/retrieve',
              content: 'getCompanyUsernameRetrieve'
            },
            {
              title: 'Check Availability',
              method: 'GET',
              path: '/check',
              content: 'getCompanyUsernameAvailability'
            },
            {
              title: 'Update Username',
              method: 'PUT',
              path: '/update',
              content: 'getCompanyUsernameUpdate'
            }
          ]
        },
        {
          title: 'Approval',
          prefix: '/approval',
          items: [
            {
              title: 'Retrieve Approval',
              method: 'GET',
              path: '/retrieve',
              content: 'getCompanyApprovalRetrieve'
            },
            {
              title: 'Request Approval',
              method: 'POST',
              path: '/request',
              content: 'getCompanyApprovalRequest'
            }
          ]
        }
      ]
    },
    {
      title: 'Discounts',
      prefix: '/discounts',
      items: [
        {
          title: 'List Discounts',
          path: '/list',
          method: 'GET',
          content: 'getCompanyDiscountList'
        },
        {
          title: 'Create Discount',
          path: '/create',
          method: 'POST',
          content: 'getCompanyDiscountCreate'
        },
        {
          title: 'Retrieve Discount',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyDiscountRetrieve'
        },
        {
          title: 'Update Discount',
          path: '/update',
          method: 'PUT',
          content: 'getCompanyDiscountUpdate'
        }
      ]
    },
    {
      title: 'Employee',
      public: false,
      prefix: '/employee',
      items: [
        {
          title: 'Whoami',
          path: '/whoami',
          method: 'GET',
          content: 'getCompanyEmployeeWhoAmI'
        },
        {
          title: 'Logout',
          path: '/logout',
          method: 'POST',
          content: 'getCompanyEmployeeLogout'
        },
        {
          title: 'Notifications',
          public: false,
          prefix: '/notifications',
          items: [
            {
              title: 'List Notifications',
              path: '/list',
              method: 'GET',
              content: 'getCompanyEmployeeNotificationList'
            },
            {
              title: 'Retrieve Notification',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyEmployeeNotificationRetrieve'
            },
            {
              title: 'Delete Notification',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyEmployeeNotificationDelete'
            },
            {
              title: 'Unread Count',
              path: '/unread-count',
              method: 'GET',
              content: 'getCompanyEmployeeNotificationUnreadCount'
            },
            {
              title: 'Mark Notifications',
              path: '/mark',
              method: 'POST',
              content: 'getCompanyEmployeeNotificationsMark'
            },
            {
              title: 'Mark All As Read',
              path: '/mark-all-as-read',
              method: 'POST',
              content: 'getCompanyEmployeeNotificationsReadAll'
            }
          ]
        }
      ]
    },
    {
      title: 'Employees',
      public: false,
      prefix: '/employees',
      items: [
        {
          title: 'List Employees',
          path: '/list',
          method: 'GET',
          content: 'getCompanyEmployeeList'
        },
        {
          title: 'Invite Employee',
          path: '/invite',
          method: 'POST',
          content: 'getCompanyEmployeeInvite'
        },
        {
          title: 'Retrieve Employee',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyEmployeeRetrieve'
        },
        {
          title: 'Update Employee',
          path: '/update',
          method: 'PUT',
          content: 'getCompanyEmployeeUpdate'
        },
        {
          title: 'Delete Employee',
          path: '/delete',
          method: 'DELETE',
          content: 'getCompanyEmployeeDelete'
        },
        {
          title: 'Employee Activation',
          path: '/activation',
          method: 'PUT',
          content: 'getCompanyEmployeeActivation'
        },
        {
          title: 'Resend Invitation',
          path: '/resend-invitation',
          method: 'POST',
          content: 'getCompanyEmployeeInvitationResend'
        },
        {
          title: 'Cancel Invitation',
          path: '/cancel-invitation',
          method: 'DELETE',
          content: 'getCompanyEmployeeInvitationCancel'
        }
      ]
    },
    {
      title: 'Online Payments',
      prefix: '/online-payments',
      items: [
        {
          title: 'Balances',
          prefix: '/balances',
          items: [
            {
              title: 'List Balances',
              path: '/list',
              method: 'GET',
              content: 'getCompanyBalanceList'
            },
            {
              title: 'Retrieve Balance',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyBalanceRetrieve'
            }
          ]
        },
        {
          title: 'Checkouts',
          prefix: '/checkouts',
          items: [
            {
              title: 'List Checkouts',
              path: '/list',
              method: 'GET',
              content: 'getCompanyCheckoutList'
            },
            {
              title: 'Retrieve Checkout',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyCheckoutRetrieve'
            }
          ]
        },
        {
          title: 'Payment Links',
          prefix: '/payment-links',
          items: [
            {
              title: 'List Payment Links',
              path: '/list',
              method: 'GET',
              content: 'getCompanyPaymentLinkList'
            },
            {
              title: 'Create Payment Link',
              path: '/create',
              method: 'POST',
              content: 'getCompanyPaymentLinkCreate'
            },
            {
              title: 'Retrieve Payment Link',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyPaymentLinkRetrieve'
            },
            {
              title: 'Update Payment Link',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyPaymentLinkUpdate'
            },
            {
              title: 'Cancel Payment Link',
              path: '/cancel',
              method: 'PUT',
              content: 'getCompanyPaymentLinkCancel'
            },
            {
              title: 'Payment Link Checkout',
              path: '/retrieve-checkout',
              method: 'GET',
              content: 'getCompanyPaymentLinkCheckout'
            }
          ]
        },
        {
          title: 'Payment Methods',
          prefix: '/payment-methods',
          items: [
            {
              title: 'List Payment Methods',
              path: '/list',
              method: 'GET',
              content: 'getCompanyPaymentMethodList'
            },
            {
              title: 'Retrieve Payment Method',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyPaymentMethodRetrieve'
            },
            {
              title: 'Update Payment Method',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyPaymentMethodUpdate'
            },
            {
              title: 'List Currencies',
              path: '/lists-currencies',
              method: 'GET',
              content: 'getCompanyPaymentMethodsCurrencyList'
            }
          ]
        },
        {
          title: 'Transactions',
          prefix: '/transactions',
          items: [
            {
              title: 'List Transactions',
              path: '/list',
              method: 'GET',
              content: 'getCompanyTransactionList'
            },
            {
              title: 'Retrieve Transaction',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyTransactionRetrieve'
            }
          ]
        }
      ]
    },
    {
      title: 'Permissions',
      public: false,
      prefix: '/permissions',
      items: [
        {
          title: 'List Permissions',
          path: '/list',
          method: 'GET',
          content: 'getCompanyPermissionList'
        }
      ]
    },
    {
      title: 'Store',
      prefix: '/store',
      items: [
        {
          title: 'Product Categories',
          prefix: '/categories',
          items: [
            {
              title: 'List Categories',
              path: '/list',
              method: 'GET',
              content: 'getCompanyProductCategoryList'
            },
            {
              title: 'Create Category',
              path: '/create',
              method: 'POST',
              content: 'getCompanyProductCategoryCreate'
            },
            {
              title: 'Retrieve Category',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyProductCategoryRetrieve'
            },
            {
              title: 'Update Category',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyProductCategoryUpdate'
            },
            {
              title: 'Delete Category',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyProductCategoryDelete'
            },
            {
              title: 'Restore Category',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyProductCategoryRestore'
            }
          ]
        },
        {
          title: 'Product Brands',
          prefix: '/brands',
          items: [
            {
              title: 'List Brands',
              path: '/list',
              method: 'GET',
              content: 'getCompanyProductBrandList'
            },
            {
              title: 'Create Brand',
              path: '/create',
              method: 'POST',
              content: 'getCompanyProductBrandCreate'
            },
            {
              title: 'Retrieve Brand',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyProductBrandRetrieve'
            },
            {
              title: 'Update Brand',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyProductBrandUpdate'
            },
            {
              title: 'Delete Brand',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyProductBrandDelete'
            },
            {
              title: 'Restore Brand',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyProductBrandRestore'
            }
          ]
        }
      ]
    },
    {
      title: 'Request Logs',
      prefix: '/request-logs',
      items: [
        {
          title: 'List Request Logs',
          path: '/list',
          method: 'GET',
          content: 'getCompanyRequestLogList'
        },
        {
          title: 'Retrieve Request Log',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyRequestLogRetrieve'
        }
      ]
    },
    {
      title: 'Roles',
      public: false,
      prefix: '/roles',
      items: [
        {
          title: 'List Roles',
          path: '/list',
          method: 'GET',
          content: 'getCompanyRoleList'
        },
        {
          title: 'Create Role',
          path: '/create',
          method: 'POST',
          content: 'getCompanyRoleCreate'
        },
        {
          title: 'Retrieve Role',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyRoleRetrieve'
        },
        {
          title: 'Update Role',
          path: '/update',
          method: 'PUT',
          content: 'getCompanyRoleUpdate'
        }
      ]
    },
    // {
    //   title: 'Products',
    //   prefix: '/products',
    //   items: [
    //     {
    //       title: 'List Products',
    //       path: '/list',
    //       method: 'GET'
    //     },
    //     {
    //       title: 'Send Product',
    //       path: '/create',
    //       method: 'POST'
    //     },
    //     {
    //       title: 'Cancel Product',
    //       path: '/update',
    //       method: 'PUT'
    //     }
    //   ]
    // },
    {
      title: 'Taxes',
      prefix: '/taxes',
      items: [
        {
          title: 'List Taxes',
          path: '/list',
          method: 'GET',
          content: 'getCompanyTaxList'
        },
        {
          title: 'Create Tax',
          path: '/create',
          method: 'POST',
          content: 'getCompanyTaxCreate'
        },
        {
          title: 'Retrieve Tax',
          path: '/retrieve',
          method: 'GET',
          content: 'getCompanyTaxRetrieve'
        },
        {
          title: 'Update Tax',
          path: '/update',
          method: 'PUT',
          content: 'getCompanyTaxUpdate'
        }
      ]
    },
    {
      title: 'Support',
      public: false,
      prefix: '/support',
      items: [
        {
          title: 'Tickets',
          prefix: '/tickets',
          items: [
            {
              title: 'List Tickets',
              path: '/list',
              method: 'GET',
              content: 'getCompanySupportTicketList'
            },
            {
              title: 'Create Ticket',
              path: '/create',
              method: 'POST',
              content: 'getCompanySupportTicketCreate'
            },
            {
              title: 'Retrieve Ticket',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanySupportTicketRetrieve'
            },
            {
              title: 'Close Ticket',
              path: '/close',
              method: 'POST',
              content: 'getCompanySupportTicketClose'
            }
          ]
        },
        {
          title: 'Comments',
          prefix: '/comments',
          items: [
            {
              title: 'List Comments',
              path: '/list',
              method: 'GET',
              content: 'getCompanySupportTicketCommentList'
            },
            {
              title: 'Create Comment',
              path: '/create',
              method: 'POST',
              content: 'getCompanySupportTicketCommentCreate'
            },
            {
              title: 'Retrieve Comment',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanySupportTicketCommentRetrieve'
            }
          ]
        }
      ]
    },
    {
      title: 'Webhooks',
      prefix: '/webhooks',
      items: [
        {
          title: 'Webhook Event Types',
          prefix: '/webhook-event-types',
          public: false,
          items: [
            {
              title: 'List Webhook Events',
              path: '/list',
              method: 'GET',
              content: 'getCompanyWebhookEventTypeList'
            }
          ]
        },
        {
          title: 'Webhook URLs',
          prefix: '/webhook-urls',
          public: false,
          items: [
            {
              title: 'List Webhook URLs',
              path: '/list',
              method: 'GET',
              content: 'getCompanyWebhookUrlList'
            },
            {
              title: 'Create Webhook URL',
              path: '/create',
              method: 'POST',
              content: 'getCompanyWebhookUrlCreate'
            },
            {
              title: 'Reset Signature',
              path: '/reset-signature',
              method: 'POST',
              content: 'getCompanyWebhookUrlResetSignature'
            },
            {
              title: 'Retrieve Webhook URL',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyWebhookUrlRetrieve'
            },
            {
              title: 'Update Webhook URL',
              path: '/update',
              method: 'PUT',
              content: 'getCompanyWebhookUrlUpdate'
            },
            {
              title: 'Delete Webhook URL',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyWebhookUrlDelete'
            },
            {
              title: 'Restore Webhook URL',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyWebhookUrlRestore'
            }
          ]
        },
        {
          title: 'Webhook Events',
          prefix: '/webhook-events',
          items: [
            {
              title: 'List Webhook Events',
              path: '/list',
              method: 'GET',
              content: 'getCompanyWebhookEventList'
            },
            {
              title: 'Retrieve Webhook Event',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyWebhookEventRetrieve'
            },
            {
              title: 'Delete Webhook Event',
              path: '/delete',
              method: 'DELETE',
              content: 'getCompanyWebhookEventDelete'
            },
            {
              title: 'Restore Webhook Event',
              path: '/restore',
              method: 'PUT',
              content: 'getCompanyWebhookEventRestore'
            },
            {
              title: 'Attempts',
              prefix: '/attempts',
              items: [
                {
                  title: 'List Event Attempts',
                  path: '/list',
                  method: 'GET',
                  content: 'getCompanySoloWebhookEventAttemptList'
                },
                {
                  title: 'Send Webhook Event',
                  path: '/send',
                  method: 'POST',
                  content: 'getCompanySoloWebhookEventSend'
                }
              ]
            }
          ]
        },
        {
          title: 'Webhook Event Attempts',
          prefix: '/webhook-event-attempts',
          items: [
            {
              title: 'List Attempts',
              path: '/list',
              method: 'GET',
              content: 'getCompanyWebhookEventAttemptList'
            },
            {
              title: 'Retrieve Attempt',
              path: '/retrieve',
              method: 'GET',
              content: 'getCompanyWebhookEventAttemptRetrieve'
            }
          ]
        },
        {
          title: 'Verify Webhook Signature',
          prefix: '/signatures',
          items: [
            {
              title: 'Verify Signature',
              path: '/verify',
              method: 'POST',
              content: 'getCompanyWebhookSignatureVerify'
            }
          ]
        }
      ]
    }
  ]
})
