import { DocItem } from '@/types'

export const getPaymentResourceList = (): DocItem => ({
  title: 'Payment Resources',
  public: false,
  items: [
    {
      prefix: '/v1/payment',
      items: [
        {
          title: 'Checkouts',
          prefix: '/checkouts',
          items: [
            {
              title: 'Retrieve Checkout',
              path: '/retrieve',
              method: 'GET',
              content: 'getPaymentCheckoutItem'
            },
            {
              title: 'Pay Checkout',
              path: '/pay',
              method: 'POST',
              content: 'getPaymentCheckoutPay'
            },
            {
              title: 'Hosted Sessions',
              prefix: '/sessions',
              items: [
                {
                  title: 'Retrieve Info',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPaymentCheckoutHostedSessionInfo'
                },
                {
                  title: 'Create Session',
                  path: '/create',
                  method: 'POST',
                  content: 'getPaymentCheckoutCreateHostedSession'
                }
              ]
            }
          ]
        },
        {
          title: 'Offline Order Pay',
          prefix: '/offline-order-pay',
          items: [
            {
              title: 'Retrieve Details',
              path: '/details',
              method: 'GET',
              content: 'getPaymentOfflineOrderPayDetails'
            },
            {
              title: 'Process Pay',
              path: '/process',
              method: 'POST',
              content: 'getPaymentOfflineOrderPayProcess'
            }
          ]
        }
      ]
    }
  ]
})
