import { DocItem } from '@/types'

export const getIndividualResourceList = (): DocItem => ({
  title: 'Individual Resources',
  public: false,
  items: [
    {
      prefix: '/v1/individual',
      items: [
        {
          title: 'Whoami',
          path: '/whoami',
          method: 'GET',
          content: 'getIndividualWhoAmI'
        },
        {
          title: 'Authentication',
          prefix: '/authentication',
          items: [
            {
              title: 'Register',
              path: '/register',
              method: 'POST',
              content: 'getIndividualRegister'
            },
            {
              title: 'Login',
              path: '/login',
              method: 'POST',
              content: 'getIndividualLogin'
            },
            {
              title: 'Request Social Login',
              path: '/request-social-login',
              method: 'GET',
              content: 'getIndividualRequestSocialLogin'
            },
            {
              title: 'Process Social Login',
              path: '/process-social-login',
              method: 'POST',
              content: 'getIndividualProcessSocialLogin'
            },
            {
              title: 'Logout',
              path: '/logout',
              method: 'POST',
              content: 'getIndividualLogout'
            }
          ]
        },
        {
          title: 'Reset Password',
          prefix: '/password-reset',
          items: [
            {
              title: 'Reset Info',
              path: '/request-info',
              method: 'GET',
              content: 'getIndividualPasswordResetInfo'
            },
            {
              title: 'Request Reset',
              path: '/request-reset',
              method: 'POST',
              content: 'getIndividualPasswordResetRequest'
            },
            {
              title: 'Process Reset',
              path: '/process-reset',
              method: 'PUT',
              content: 'getIndividualPasswordResetProcess'
            }
          ]
        },
        {
          title: 'Companies',
          prefix: '/companies',
          items: [
            {
              title: 'Create Company',
              path: '/create',
              method: 'POST',
              content: 'getIndividualCreateNewCompany'
            },
            {
              title: 'Pay On Behalf',
              path: '/pay-on-behalf',
              method: 'GET',
              content: 'getIndividualPayOnBehalfOfCompanies'
            }
          ]
        },
        {
          title: 'Employments',
          prefix: '/employments',
          items: [
            {
              title: 'List Employments',
              path: '/list',
              method: 'GET',
              content: 'getIndividualCompanyEmploymentList'
            },
            {
              title: 'Employment Token',
              path: '/token',
              method: 'POST',
              content: 'getIndividualCompanyEmploymentToken'
            },
            {
              title: 'Retrieve Employment',
              path: '/retrieve',
              method: 'GET',
              content: 'getIndividualCompanyEmploymentRetrieve'
            }
          ]
        },
        {
          title: 'Employment Invitation',
          prefix: '/employment-invitation',
          items: [
            {
              title: 'Invitation Info',
              path: '/retrieve-info',
              method: 'GET',
              content: 'getIndividualCompanyEmploymentInvitationInfo'
            },
            {
              title: 'Accept Invitation',
              path: '/accept',
              method: 'POST',
              content: 'getIndividualCompanyEmploymentInvitationAccept'
            }
          ]
        },
        {
          title: 'Notifications',
          prefix: '/notifications',
          items: [
            {
              title: 'List Notifications',
              path: '/list',
              method: 'GET',
              content: 'getIndividualNotificationList'
            },
            {
              title: 'Retrieve Notification',
              path: '/retrieve',
              method: 'GET',
              content: 'getIndividualNotificationRetrieve'
            },
            {
              title: 'Delete Notification',
              path: '/delete',
              method: 'DELETE',
              content: 'getIndividualNotificationDelete'
            },
            {
              title: 'Unread Count',
              path: '/unread-count',
              method: 'GET',
              content: 'getIndividualNotificationUnreadCount'
            },
            {
              title: 'Mark Notifications',
              path: '/mark',
              method: 'POST',
              content: 'getIndividualNotificationsMark'
            },
            {
              title: 'Mark All As Read',
              path: '/mark-all-as-read',
              method: 'POST',
              content: 'getIndividualNotificationsReadAll'
            }
          ]
        },
        {
          title: 'Profile',
          prefix: '/profile',
          items: [
            {
              title: 'Update Profile',
              method: 'PUT',
              path: '/update',
              content: 'getIndividualProfileUpdate'
            },
            {
              title: 'Password Change',
              method: 'PUT',
              path: '/password-change',
              content: 'getIndividualProfilePasswordChange'
            },
            {
              title: 'Avatar',
              prefix: '/avatar',
              items: [
                {
                  title: 'Upload Avatar',
                  method: 'POST',
                  path: '/upload',
                  content: 'getIndividualProfileAvatarUpload'
                },
                {
                  title: 'Delete Avatar',
                  method: 'DELETE',
                  path: '/delete',
                  content: 'getIndividualProfileAvatarDelete'
                }
              ]
            },
            {
              title: 'Verification Documents',
              prefix: '/documents',
              items: [
                {
                  title: 'List Documents',
                  method: 'GET',
                  path: '/list',
                  content: 'getIndividualVerificationDocumentList'
                },
                {
                  title: 'Upload Document',
                  method: 'POST',
                  path: '/upload',
                  content: 'getIndividualVerificationDocumentUpload'
                }
              ]
            },
            {
              title: 'Email',
              items: [
                {
                  title: 'Send Verify Link',
                  method: 'POST',
                  path: '/verify-email',
                  content: 'getResourceIndividualProfileEmailVerifyRequest'
                }
              ]
            },
            {
              title: 'Phonenumber',
              prefix: '/phonenumber',
              items: [
                {
                  title: 'Send OTP',
                  method: 'POST',
                  path: '/send-otp',
                  content: 'getIndividualProfilePhoneNumberSendOTP'
                },
                {
                  title: 'Verify OTP',
                  method: 'POST',
                  path: '/verify-otp',
                  content: 'getIndividualProfilePhoneNumberVerifyWithOTP'
                }
              ]
            },
            {
              title: 'Approval',
              prefix: '/approval',
              items: [
                {
                  title: 'Retrieve Approval',
                  method: 'GET',
                  path: '/retrieve',
                  content: 'getIndividualApprovalRetrieve'
                },
                {
                  title: 'Request Approval',
                  method: 'POST',
                  path: '/request',
                  content: 'getIndividualApprovalRequest'
                }
              ]
            }
          ]
        },
        {
          title: 'Username',
          prefix: '/username',
          items: [
            {
              title: 'Retrieve Username',
              method: 'GET',
              path: '/retrieve',
              content: 'getIndividualUsernameRetrieve'
            },
            {
              title: 'Check Availability',
              method: 'GET',
              path: '/check',
              content: 'getIndividualUsernameAvailability'
            },
            {
              title: 'Update Username',
              method: 'PUT',
              path: '/update',
              content: 'getIndividualUsernameUpdate'
            }
          ]
        },
        {
          title: 'Twofa',
          prefix: '/twofa',
          items: [
            {
              title: 'Retrieve Info',
              method: 'GET',
              path: '/retrieve',
              content: 'getIndividualTwofaInfo'
            },
            {
              title: 'Generate Secret',
              method: 'POST',
              path: '/generate',
              content: 'getIndividualTwofaSecretGenerate'
            },
            {
              title: 'Activate Secret',
              method: 'POST',
              path: '/activate',
              content: 'getIndividualTwofaSecretActivate'
            },
            {
              title: 'Reset Secret',
              method: 'POST',
              path: '/reset',
              content: 'getIndividualTwofaSecretReset'
            }
          ]
        },
        {
          title: 'Verifications',
          items: [
            {
              title: 'Verify Email',
              method: 'POST',
              path: '/verify-user-email',
              content: 'getIndividualVerifyEmailViaToken'
            }
          ]
        },
        {
          title: 'Support',
          prefix: '/support',
          items: [
            {
              title: 'Tickets',
              prefix: '/tickets',
              items: [
                {
                  title: 'List Tickets',
                  path: '/list',
                  method: 'GET',
                  content: 'getIndividualSupportTicketList'
                },
                {
                  title: 'Create Ticket',
                  path: '/create',
                  method: 'POST',
                  content: 'getIndividualSupportTicketCreate'
                },
                {
                  title: 'Retrieve Ticket',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getIndividualSupportTicketRetrieve'
                },
                {
                  title: 'Close Ticket',
                  path: '/close',
                  method: 'POST',
                  content: 'getIndividualSupportTicketClose'
                }
              ]
            },
            {
              title: 'Comments',
              prefix: '/comments',
              items: [
                {
                  title: 'List Comments',
                  path: '/list',
                  method: 'GET',
                  content: 'getIndividualSupportTicketCommentList'
                },
                {
                  title: 'Create Comment',
                  path: '/create',
                  method: 'POST',
                  content: 'getIndividualSupportTicketCommentCreate'
                },
                {
                  title: 'Retrieve Comment',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getIndividualSupportTicketCommentRetrieve'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
})
