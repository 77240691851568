<template>
  <ul
    :class="[
      {
        'ps-2': child
      }
    ]"
  >
    <component
      :is="component"
      v-for="({ item, component }, itemIndex) in normalizedItems"
      :key="itemIndex"
      class="w-full"
      v-bind="item"
    />
  </ul>
</template>

<script setup lang="ts">
import { excludeKeys } from '@payiano/ha-utils'
import AppDrawerNavGroupItem from './DrawerNavGroupItem.vue'
import AppDrawerNavItem from './DrawerNavItem.vue'
import { NormalizedDocGroupItem, IDocSingleItem } from '@/types'

const props = defineProps<{
  items: (IDocSingleItem | NormalizedDocGroupItem)[]
  child?: boolean
}>()

const normalizedItems = computed(() =>
  props.items.map((item) => {
    const isGroup = (item as NormalizedDocGroupItem).items?.length
    const _item = isGroup ? item : excludeKeys(item, ['isGroup'])

    return {
      component: isGroup ? AppDrawerNavGroupItem : AppDrawerNavItem,
      item: _item
    }
  })
)
</script>
