import { computed } from 'vue'
import {
  getAllyResourceList,
  getCompanyResourceList,
  getIndividualResourceList,
  getPartnerResourceList,
  getPayianoResourceList,
  getPaymentResourceList
} from '@/docs'
import { getNormalizedDocItems } from '@/util'
import { DocItem } from '@/types'

export const useDrawerItems = () => {
  const items: DocItem[] = [
    {
      opened: true,
      title: 'RESTFUL APIs',
      prefix: '/api/rest',
      items: [
        {
          title: 'Get Started',
          path: '/get-started'
        },
        getIndividualResourceList(),
        getCompanyResourceList(),
        getPaymentResourceList(),
        getPartnerResourceList(),
        getPayianoResourceList(),
        getAllyResourceList(),
        {
          title: 'Reference',
          prefix: '/reference',
          items: [
            {
              title: 'Webhooks',
              prefix: '/webhooks',
              items: [
                {
                  title: 'Webhooks Overview',
                  path: '/overview'
                },
                {
                  title: 'Webhooks Event Types',
                  path: '/event-types'
                },
                {
                  title: 'Build Webhook Signature',
                  path: '/build-signature'
                },
                {
                  title: 'Verify Webhook Signature',
                  path: '/verify-signature'
                }
              ]
            },
            {
              path: '/currency-codes',
              title: 'Currency Codes'
            },
            {
              path: '/datetime-formats',
              title: 'Datetime Formats'
            },
            {
              title: 'Errors Handling',
              path: '/errors-handling'
            },
            {
              title: 'Identify Resource',
              path: '/identify-resource'
            },
            {
              title: 'Including Resources',
              path: '/including-resources'
            },
            {
              title: 'Pagination',
              path: '/pagination'
            },
            {
              path: '/processing-fees-strategy',
              title: 'Processing Fees Strategy'
            },
            {
              title: 'Tax Calculations',
              path: '/tax-calculations'
            },
            {
              title: 'Test Pay Order (POS)',
              path: '/test-pay-order'
            }
          ]
        }
      ]
    },
    {
      title: 'INTEGRATION AND PLUGINS',
      prefix: '/plugins',
      items: [
        {
          title: 'Pay Button',
          path: '/pay-button'
        }
      ]
    }
  ]

  const normalizedItems = computed(() => getNormalizedDocItems(items))

  return normalizedItems
}
