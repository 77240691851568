import { DocItem } from '@/types'

export const getPartnerResourceList = (): DocItem => ({
  title: 'Partner Resources',
  items: [
    {
      prefix: '/v1/partner',
      items: [
        {
          title: 'App',
          prefix: '/app',
          items: [
            {
              title: 'Whoami',
              path: '/whoami',
              method: 'GET',
              content: 'getPartnerAppWhoAmI'
            }
          ]
        },
        {
          title: 'Apps',
          public: false,
          prefix: '/apps',
          items: [
            {
              title: 'List Apps',
              path: '/list',
              method: 'GET',
              content: 'getPartnerAppList'
            },
            {
              title: 'Create App',
              path: '/create',
              method: 'POST',
              content: 'getPartnerAppCreate'
            },
            {
              title: 'Reset App Token',
              path: '/reset-token',
              method: 'POST',
              content: 'getPartnerAppTokenReset'
            },
            {
              title: 'Retrieve App',
              path: '/retrieve',
              method: 'GET',
              content: 'getPartnerAppRetrieve'
            },
            {
              title: 'Update App',
              path: '/update',
              method: 'PUT',
              content: 'getPartnerAppUpdate'
            }
          ]
        },
        {
          title: 'Autocomplete',
          public: false,
          prefix: '/autocomplete',
          items: [
            {
              title: 'Roles',
              path: '/roles',
              method: 'GET',
              content: 'getPartnerAutoCompleteRoles'
            }
          ]
        },
        {
          title: 'Dashboard',
          public: false,
          prefix: '/dashboard',
          items: [
            {
              title: 'Transactions',
              path: '/transactions',
              method: 'GET',
              content: 'getPartnerDashboardTransactionsStatistics'
            }
          ]
        },
        {
          public: false,
          title: 'Employee',
          prefix: '/employee',
          items: [
            {
              title: 'Whoami',
              path: '/whoami',
              method: 'GET',
              content: 'getPartnerAuthEmployeeWhoAmI'
            },
            {
              title: 'Authentication',
              prefix: '/authentication',
              items: [
                {
                  title: 'Login',
                  path: '/login',
                  method: 'POST',
                  content: 'getPartnerEmployeeLogin'
                },
                {
                  title: 'Request Social Login',
                  path: '/request-social-login',
                  method: 'GET',
                  content: 'getPartnerEmployeeRequestSocialLogin'
                },
                {
                  title: 'Process Social Login',
                  path: '/process-social-login',
                  method: 'POST',
                  content: 'getPartnerEmployeeProcessSocialLogin'
                },
                {
                  title: 'Logout',
                  path: '/logout',
                  method: 'POST',
                  content: 'getPartnerEmployeeLogout'
                }
              ]
            },
            {
              title: 'Profile',
              prefix: '/profile',
              items: [
                {
                  title: 'Update Profile',
                  method: 'PUT',
                  path: '/update',
                  content: 'getPartnerEmployeeProfileUpdate'
                },
                {
                  title: 'Password Change',
                  method: 'PUT',
                  path: '/password-change',
                  content: 'getPartnerEmployeeProfilePasswordChange'
                },
                {
                  title: 'Avatar',
                  prefix: '/avatar',
                  items: [
                    {
                      title: 'Upload Avatar',
                      method: 'POST',
                      path: '/upload',
                      content: 'getPartnerEmployeeProfileAvatarUpload'
                    },
                    {
                      title: 'Delete Avatar',
                      method: 'DELETE',
                      path: '/delete',
                      content: 'getPartnerEmployeeProfileAvatarDelete'
                    }
                  ]
                },
                {
                  title: 'Email',
                  items: [
                    {
                      title: 'Send Verify Link',
                      method: 'POST',
                      path: '/verify-email',
                      content:
                        'getResourcePartnerEmployeeProfileEmailVerifyRequest'
                    }
                  ]
                },
                {
                  title: 'Phonenumber',
                  prefix: '/phonenumber',
                  items: [
                    {
                      title: 'Send OTP',
                      method: 'POST',
                      path: '/send-otp',
                      content: 'getPartnerEmployeeProfilePhoneNumberSendOTP'
                    },
                    {
                      title: 'Verify OTP',
                      method: 'POST',
                      path: '/verify-otp',
                      content:
                        'getPartnerEmployeeProfilePhoneNumberVerifyWithOTP'
                    }
                  ]
                }
              ]
            },
            {
              title: 'Reset Password',
              prefix: '/password-reset',
              items: [
                {
                  title: 'Reset Info',
                  path: '/request-info',
                  method: 'GET',
                  content: 'getPartnerEmployeePasswordResetInfo'
                },
                {
                  title: 'Request Reset',
                  path: '/request-reset',
                  method: 'POST',
                  content: 'getPartnerEmployeePasswordResetRequest'
                },
                {
                  title: 'Process Reset',
                  path: '/process-reset',
                  method: 'PUT',
                  content: 'getPartnerEmployeePasswordResetProcess'
                }
              ]
            },
            {
              title: 'Notifications',
              public: false,
              prefix: '/notifications',
              items: [
                {
                  title: 'List Notifications',
                  path: '/list',
                  method: 'GET',
                  content: 'getPartnerEmployeeNotificationList'
                },
                {
                  title: 'Retrieve Notification',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPartnerEmployeeNotificationRetrieve'
                },
                {
                  title: 'Delete Notification',
                  path: '/delete',
                  method: 'DELETE',
                  content: 'getPartnerEmployeeNotificationDelete'
                },
                {
                  title: 'Unread Count',
                  path: '/unread-count',
                  method: 'GET',
                  content: 'getPartnerEmployeeNotificationUnreadCount'
                },
                {
                  title: 'Mark Notifications',
                  path: '/mark',
                  method: 'POST',
                  content: 'getPartnerEmployeeNotificationsMark'
                },
                {
                  title: 'Mark All As Read',
                  path: '/mark-all-as-read',
                  method: 'POST',
                  content: 'getPartnerEmployeeNotificationsReadAll'
                }
              ]
            }
          ]
        },
        {
          public: false,
          title: 'Partner',
          prefix: '/partner',
          items: [
            {
              title: 'Whoami',
              path: '/whoami',
              method: 'GET',
              content: 'getPartnerWhoAmI'
            }
          ]
        },
        {
          title: 'Employee Invitation',
          public: false,
          prefix: '/employee-invitation',
          items: [
            {
              title: 'Invitation Info',
              path: '/retrieve-info',
              method: 'GET',
              content: 'getPartnerEmployeeInvitationInfo'
            },
            {
              title: 'Accept Invitation',
              path: '/accept',
              method: 'POST',
              content: 'getPartnerEmployeeInvitationAccept'
            }
          ]
        },
        {
          title: 'Employees',
          public: false,
          prefix: '/employees',
          items: [
            {
              title: 'List Employees',
              path: '/list',
              method: 'GET',
              content: 'getPartnerEmployeeList'
            },
            {
              title: 'Invite Employee',
              path: '/invite',
              method: 'POST',
              content: 'getPartnerEmployeeInvite'
            },
            {
              title: 'Retrieve Employee',
              path: '/retrieve',
              method: 'GET',
              content: 'getPartnerEmployeeRetrieve'
            },
            {
              title: 'Update Employee',
              path: '/update',
              method: 'PUT',
              content: 'getPartnerEmployeeUpdate'
            },
            {
              title: 'Delete Employee',
              path: '/delete',
              method: 'DELETE',
              content: 'getPartnerEmployeeDelete'
            },
            {
              title: 'Employee Activation',
              path: '/employee-activation',
              method: 'PUT',
              content: 'getPartnerEmployeeActivation'
            },
            {
              title: 'Resend Invitation',
              path: '/resend-invitation',
              method: 'POST',
              content: 'getPartnerEmployeeInvitationResend'
            },
            {
              title: 'Cancel Invitation',
              path: '/cancel-invitation',
              method: 'DELETE',
              content: 'getPartnerEmployeeInvitationCancel'
            }
          ]
        },
        {
          title: 'Permissions',
          public: false,
          prefix: '/permissions',
          items: [
            {
              title: 'List Permissions',
              path: '/list',
              method: 'GET',
              content: 'getPartnerPermissionList'
            }
          ]
        },
        {
          title: 'Roles',
          public: false,
          prefix: '/roles',
          items: [
            {
              title: 'List Roles',
              path: '/list',
              method: 'GET',
              content: 'getPartnerRoleList'
            },
            {
              title: 'Create Role',
              path: '/create',
              method: 'POST',
              content: 'getPartnerRoleCreate'
            },
            {
              title: 'Retrieve Role',
              path: '/retrieve',
              method: 'GET',
              content: 'getPartnerRoleRetrieve'
            },
            {
              title: 'Update Role',
              path: '/update',
              method: 'PUT',
              content: 'getPartnerRoleUpdate'
            }
          ]
        },
        {
          title: 'Cash Collection',
          prefix: '/cash-collection',
          items: [
            {
              title: 'Payers',
              prefix: '/payers',
              items: [
                {
                  title: 'List Payers',
                  path: '/list',
                  method: 'GET',
                  content: 'getPartnerCashCollectionPayerList'
                },
                {
                  title: 'Retrieve Payer',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPartnerCashCollectionPayerRetrieve'
                }
              ]
            },
            {
              title: 'Transactions',
              prefix: '/transactions',
              items: [
                {
                  title: 'List Transactions',
                  path: '/list',
                  method: 'GET',
                  content: 'getPartnerCashCollectionTransactionList'
                },
                {
                  title: 'Pay Transaction',
                  path: '/pay',
                  method: 'POST',
                  content: 'getPartnerCashCollectionTransactionPay'
                },
                {
                  title: 'Retrieve Transaction',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPartnerCashCollectionTransactionRetrieve'
                },
                {
                  title: 'Update Transaction',
                  path: '/update',
                  method: 'PUT',
                  content: 'getPartnerCashCollectionTransactionUpdate'
                }
              ]
            }
          ]
        },
        {
          public: false,
          title: 'Verifications',
          items: [
            {
              title: 'Verify Email',
              method: 'POST',
              path: '/verify-user-email',
              content: 'getPartnerEmployeeVerifyEmailViaToken'
            }
          ]
        },
        {
          title: 'Support',
          public: false,
          prefix: '/support',
          items: [
            {
              title: 'Tickets',
              prefix: '/tickets',
              items: [
                {
                  title: 'List Tickets',
                  path: '/list',
                  method: 'GET',
                  content: 'getPartnerSupportTicketList'
                },
                {
                  title: 'Create Ticket',
                  path: '/create',
                  method: 'POST',
                  content: 'getPartnerSupportTicketCreate'
                },
                {
                  title: 'Retrieve Ticket',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPartnerSupportTicketRetrieve'
                },
                {
                  title: 'Close Ticket',
                  path: '/close',
                  method: 'POST',
                  content: 'getPartnerSupportTicketClose'
                }
              ]
            },
            {
              title: 'Comments',
              prefix: '/comments',
              items: [
                {
                  title: 'List Comments',
                  path: '/list',
                  method: 'GET',
                  content: 'getPartnerSupportTicketCommentList'
                },
                {
                  title: 'Create Comment',
                  path: '/create',
                  method: 'POST',
                  content: 'getPartnerSupportTicketCommentCreate'
                },
                {
                  title: 'Retrieve Comment',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPartnerSupportTicketCommentRetrieve'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
})
