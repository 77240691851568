import { DocItem } from '@/types'

export const getPayianoResourceList = (): DocItem => ({
  title: 'Payiano Resources',
  public: false,
  items: [
    {
      prefix: '/v1/payiano',
      items: [
        {
          title: 'Autocomplete',
          public: false,
          prefix: '/autocomplete',
          items: [
            {
              title: 'Roles',
              path: '/roles',
              method: 'GET',
              content: 'getPayianoAutoCompleteRoles'
            }
          ]
        },
        {
          title: 'Employee',
          prefix: '/employee',
          items: [
            {
              title: 'Whoami',
              path: '/whoami',
              method: 'GET',
              content: 'getPayianoEmployeeWhoAmI'
            },
            {
              title: 'Profile',
              prefix: '/profile',
              items: [
                {
                  title: 'Password Change',
                  method: 'PUT',
                  path: '/password-change',
                  content: 'getPayianoEmployeeProfilePasswordChange'
                },
                {
                  title: 'Avatar',
                  prefix: '/avatar',
                  items: [
                    {
                      title: 'Upload Avatar',
                      method: 'POST',
                      path: '/upload',
                      content: 'getPayianoEmployeeProfileAvatarUpload'
                    },
                    {
                      title: 'Delete Avatar',
                      method: 'DELETE',
                      path: '/delete',
                      content: 'getPayianoEmployeeProfileAvatarDelete'
                    }
                  ]
                }
              ]
            },
            {
              title: 'Authentication',
              prefix: '/authentication',
              items: [
                {
                  title: 'Login',
                  path: '/login',
                  method: 'POST',
                  content: 'getPayianoEmployeeLogin'
                },
                {
                  title: 'Request Social Login',
                  path: '/request-social-login',
                  method: 'GET',
                  content: 'getPayianoEmployeeRequestSocialLogin'
                },
                {
                  title: 'Process Social Login',
                  path: '/process-social-login',
                  method: 'POST',
                  content: 'getPayianoEmployeeProcessSocialLogin'
                },
                {
                  title: 'Logout',
                  path: '/logout',
                  method: 'POST',
                  content: 'getPayianoEmployeeLogout'
                }
              ]
            },
            {
              title: 'Reset Password',
              prefix: '/password-reset',
              items: [
                {
                  title: 'Reset Info',
                  path: '/request-info',
                  method: 'GET',
                  content: 'getPayianoEmployeePasswordResetInfo'
                },
                {
                  title: 'Request Reset',
                  path: '/request-reset',
                  method: 'POST',
                  content: 'getPayianoEmployeePasswordResetRequest'
                },
                {
                  title: 'Process Reset',
                  path: '/process-reset',
                  method: 'PUT',
                  content: 'getPayianoEmployeePasswordResetProcess'
                }
              ]
            },
            {
              title: 'Notifications',
              public: false,
              prefix: '/notifications',
              items: [
                {
                  title: 'List Notifications',
                  path: '/list',
                  method: 'GET',
                  content: 'getPayianoEmployeeNotificationList'
                },
                {
                  title: 'Retrieve Notification',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPayianoEmployeeNotificationRetrieve'
                },
                {
                  title: 'Delete Notification',
                  path: '/delete',
                  method: 'DELETE',
                  content: 'getPayianoEmployeeNotificationDelete'
                },
                {
                  title: 'Unread Count',
                  path: '/unread-count',
                  method: 'GET',
                  content: 'getPayianoEmployeeNotificationUnreadCount'
                },
                {
                  title: 'Mark Notifications',
                  path: '/marks',
                  method: 'POST',
                  content: 'getPayianoEmployeeNotificationsMark'
                },
                {
                  title: 'Mark All As Read',
                  path: '/mark-alls-as-read',
                  method: 'POST',
                  content: 'getPayianoEmployeeNotificationsReadAll'
                }
              ]
            }
          ]
        },
        {
          title: 'Employee Invitation',
          public: false,
          prefix: '/employee-invitation',
          items: [
            {
              title: 'Invitation Info',
              path: '/info',
              method: 'GET',
              content: 'getPayianoEmployeeInvitationInfo'
            },
            {
              title: 'Accept Invitation',
              path: '/accept',
              method: 'POST',
              content: 'getPayianoEmployeeInvitationAccept'
            }
          ]
        },
        {
          title: 'Employees',
          public: false,
          prefix: '/employees',
          items: [
            {
              title: 'List Employees',
              path: '/list',
              method: 'GET',
              content: 'getPayianoEmployeeList'
            },
            {
              title: 'Invite Employee',
              path: '/invite',
              method: 'POST',
              content: 'getPayianoEmployeeInvite'
            },
            {
              title: 'Retrieve Employee',
              path: '/retrieve',
              method: 'GET',
              content: 'getPayianoEmployeeRetrieve'
            },
            {
              title: 'Update Employee',
              path: '/update',
              method: 'PUT',
              content: 'getPayianoEmployeeUpdate'
            },
            {
              title: 'Delete Employee',
              path: '/delete',
              method: 'DELETE',
              content: 'getPayianoEmployeeDelete'
            },
            {
              title: 'Employee Activation',
              path: '/employee-activation',
              method: 'PUT',
              content: 'getPayianoEmployeeActivation'
            },
            {
              title: 'Resend Invitation',
              path: '/resend-invitation',
              method: 'POST',
              content: 'getPayianoEmployeeInvitationResend'
            },
            {
              title: 'Cancel Invitation',
              path: '/cancel-invitation',
              method: 'DELETE',
              content: 'getPayianoEmployeeInvitationCancel'
            }
          ]
        },
        {
          title: 'Permissions',
          prefix: '/permissions',
          items: [
            {
              title: 'List Permissions',
              path: '/list',
              method: 'GET',
              content: 'getPayianoPermissionList'
            }
          ]
        },
        {
          title: 'Roles',
          prefix: '/roles',
          items: [
            {
              title: 'List Roles',
              path: '/list',
              method: 'GET',
              content: 'getPayianoRoleList'
            },
            {
              title: 'Create Role',
              path: '/create',
              method: 'POST',
              content: 'getPayianoRoleCreate'
            },
            {
              title: 'Retrieve Role',
              path: '/retrieve',
              method: 'GET',
              content: 'getPayianoRoleRetrieve'
            },
            {
              title: 'Update Role',
              path: '/update',
              method: 'PUT',
              content: 'getPayianoRoleUpdate'
            }
          ]
        },
        {
          title: 'Partners',
          prefix: '/partners',
          items: [
            {
              title: 'Partners',
              path: '/partners',
              items: [
                {
                  title: 'List Partners',
                  path: '/list',
                  method: 'GET',
                  content: 'getPayianoPartnerList'
                },
                {
                  title: 'Retrieve Partner',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPayianoPartnerRetrieve'
                },
                {
                  title: 'Partner Activation',
                  path: '/activation',
                  method: 'PUT',
                  content: 'getPayianoPartnerActivation'
                }
              ]
            },
            {
              title: 'Employees',
              prefix: '/employees',
              items: [
                {
                  title: 'List Employees',
                  path: '/list',
                  method: 'GET',
                  content: 'getPayianoPartnerEmployeeList'
                },
                {
                  title: 'Invite Employee',
                  path: '/invite',
                  method: 'POST',
                  content: 'getPayianoPartnerEmployeeInvite'
                },
                {
                  title: 'Retrieve Employee',
                  path: '/retrieve',
                  method: 'GET',
                  content: 'getPayianoPartnerEmployeeRetrieve'
                },
                {
                  title: 'Resend Invitation',
                  path: '/resend-invitation',
                  method: 'POST',
                  content: 'getPayianoPartnerEmployeeInvitationResend'
                },
                {
                  title: 'Cancel Invitation',
                  path: '/cancel-invitation',
                  method: 'DELETE',
                  content: 'getPayianoPartnerEmployeeInvitationCancel'
                }
              ]
            },
            {
              title: 'Cash Collection',
              prefix: '/cash-collection',
              items: [
                {
                  title: 'Transactions',
                  prefix: '/transactions',
                  items: [
                    {
                      title: 'List Transactions',
                      path: '/list',
                      method: 'GET',
                      content: 'getPayianoPartnerCashCollectionTransactionList'
                    },
                    {
                      title: 'Retrieve Transaction',
                      path: '/retrieve',
                      method: 'GET',
                      content:
                        'getPayianoPartnerCashCollectionTransactionRetrieve'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
})
