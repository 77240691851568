<template>
  <aside
    class="fixed h-full transition-all overflow-y-auto bg-white border-e border-gray-200"
  >
    <div class="pt-5 pb-24">
      <AppDrawerNav :items="visibleItems" />
    </div>
  </aside>
</template>

<script setup lang="ts">
import AppDrawerNav from './DrawerNav.vue'
import { DocGroupItem, NormalizedDocItem } from '@/types'

const { $payianoEmployee } = useNuxtApp()

const drawerItems = useDrawerItems()

const getVisibleItems = (items: NormalizedDocItem[]) =>
  items.reduce((carry: NormalizedDocItem[], item) => {
    const visible = item.public || $payianoEmployee.loggedIn

    if (visible) {
      if (item.isGroup) {
        const items = getVisibleItems(
          (item as DocGroupItem).items as NormalizedDocItem[]
        )

        if (items.length) {
          carry.push({
            ...item,
            items
          })
        }
      } else {
        carry.push({
          ...item
        })
      }
    }

    return carry
  }, [])

const visibleItems = computed(() => getVisibleItems(drawerItems.value))
</script>
