<template>
  <AppHeader
    v-model:show-drawer="showDrawer"
    has-drawer
    class="h-[50px] lg:h-[60px]"
    :style="{ zIndex: headerIndex }"
  />

  <HaBackdrop
    :model-value="lgAndDown && showDrawer"
    :style="{ zIndex: backdropIndex }"
    @update:model-value="onChangeBackdrop"
  />

  <AppDrawer
    class="top-[50px] lg:top-[60px] w-[250px] -start-[250px] lg:start-0"
    :class="{
      '!start-0': showDrawer && isReady,
      '!-start-[250px]': !showDrawer && lgAndUp
    }"
    :style="{ zIndex: drawerIndex }"
  />

  <main
    :class="[
      'transition-all min-h-screen py-[50px] lg:py-[60px] lg:ps-[250px]',
      {
        '!ps-0': !showDrawer && lgAndUp
      }
    ]"
  >
    <div class="max-w-screen-xl mx-auto p-2 md:p-6 text-sm md:text-base">
      <NuxtPage />
    </div>
  </main>
</template>

<script lang="ts" setup>
import { HaBackdrop } from '@payiano/ha-atoms'
import {
  useDisplay,
  useLocaleOrValue,
  useStackedIndexes
} from '@payiano/ha-composables'
import { AppDrawer, AppHeader } from '@/components/Layout'

const { isReady, lgAndUp, lgAndDown } = useDisplay()

// Handle stacked `zIndex`
const { getNextIndex } = useStackedIndexes()
const backdropIndex = ref<number>()
const headerIndex = ref<number>()
const drawerIndex = ref<number>()

const setStackedIndexes = () => {
  backdropIndex.value = getNextIndex()
  drawerIndex.value = getNextIndex()
  headerIndex.value = getNextIndex()
}

const showDrawer = useLocaleOrValue({
  value: () => lgAndUp.value
})

const onChangeBackdrop = (value: boolean) => {
  showDrawer.value = !!value
}

watch(
  () => showDrawer.value,
  (newValue, oldValue) => {
    if (newValue || oldValue === undefined) {
      setStackedIndexes()
    }
  },
  {
    immediate: true
  }
)
</script>
