<template>
  <li class="de--drawer-nav-group-item">
    <button
      class="flex items-center justify-start p-1 text-base w-full hover:bg-gray-100/60"
      @click="onClickToggle"
    >
      <HaIcon
        size="22"
        :icon="mdiChevronRight"
        :class="[
          'transition-all',
          {
            'rtl:rotate-180': !isOpened
          }
        ]"
        :style="{
          rotate: isOpened ? '90deg' : undefined
        }"
      />

      <span class="text-sm truncate">{{ title }}</span>
    </button>

    <HaTransition>
      <AppDrawerNav
        v-show="isOpened"
        child
        :items="items"
        :class="{ hidden: !isOpened }"
      />
    </HaTransition>
  </li>
</template>

<script setup lang="ts">
import { mdiChevronRight } from '@mdi/js'
import { HaIcon, HaTransition } from '@payiano/ha-atoms'
import { useLocaleOrValue } from '@payiano/ha-composables'
import AppDrawerNav from './DrawerNav.vue'
import { DocItem, NormalizedDocGroupItem, IDocSingleItem } from '@/types'

const { $router } = useNuxtApp()
const props = defineProps<NormalizedDocGroupItem>()

const isOpened = useLocaleOrValue({
  value: () => props.opened
})

const checkIfItemsOpened = (items: DocItem[]): boolean =>
  !!items?.length &&
  items?.some(
    (item) =>
      (item as IDocSingleItem).path === $router.currentRoute.value.path ||
      checkIfItemsOpened((item as NormalizedDocGroupItem).items)
  )

watch(
  () => $router.currentRoute.value,
  () => {
    if (checkIfItemsOpened(props.items)) {
      isOpened.value = true
    }
  },
  { deep: true, immediate: true }
)

const onClickToggle = () => (isOpened.value = !isOpened.value)
</script>
