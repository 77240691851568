import { getAllyBasataResourceList } from './basata'
import { DocItem } from '@/types'

export const getAllyResourceList = (): DocItem => ({
  title: 'Ally Resources',
  public: false,
  items: [
    {
      prefix: '/v1/ally',
      items: [getAllyBasataResourceList()]
    }
  ]
})
